import { useEffect } from "react";

export default function Termly() {
  useEffect(() => {
    // check if the script is already loaded
    if (document.getElementById("termly-script")) {
      return;
    }

    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src =
      "https://app.termly.io/resource-blocker/09495e45-5223-4566-aef4-be064ea8c2fc?autoBlock=off";
    script.id = "termly-script";
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return <></>;
}
