export default {
  welcome:
    "Bienvenue chez SkillSynergy, où l'apprentissage de la programmation est facilité pour tous les niveaux.",
  courses: "Cours",
  algorithms: "Algorithmes",
  programming: "Programmation Fondamentale",
  architecture: "Architecture Logicielle",
  web: "Développement Web",
  mobile: "Développement Mobile",
  server: "Développement de Bases de Données et Serveurs",
  contact: "Nous Contacter",
  phone: "+49 151 525 54041",
  address: "Berlin, Allemagne",
  subscribe: "S'abonner",
  subscribeText: "Restez informé des derniers cours et offres de SkillSynergy.",
  send: "Envoyer",
  copyRight: `Copyright © ${new Date().getFullYear()} SkillSynergy. Tous Droits Réservés.`,
  terms: "Conditions d'Utilisation",
  privacy: "Politique de Confidentialité",
};
