import { Crisp } from "crisp-sdk-web";
import { useEffect } from "react";

export default function CrispButton() {
  useEffect(() => {
    Crisp.configure("14e1a0d4-437c-4b16-8b19-78e54eb67b4c");
  });

  return <></>;
}
