import { useEffect } from "react";

export default function ScrollUp() {
  useEffect(() => {
    window.addEventListener("scroll", function () {
      const scroll = window.scrollY;
      const scrollUpElement = document.querySelector(
        ".cs-scrollup",
      ) as HTMLElement;

      if (scroll >= 350) {
        scrollUpElement.classList.add("cs-scrollup_show");
      } else {
        scrollUpElement.classList.remove("cs-scrollup_show");
      }
    });
  });

  const scrollUp = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <span
      className="cs-scrollup"
      onClick={scrollUp}
      role="button"
      tabIndex={0}
      onKeyDown={() => {}}
    >
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 10L1.7625 11.7625L8.75 4.7875V20H11.25V4.7875L18.225 11.775L20 10L10 0L0 10Z"
          fill="currentColor"
        />
      </svg>
    </span>
  );
}
