export default {
  welcome:
    "Bienvenue chez SkillSynergy, où l'apprentissage de la programmation est facilité pour tous les niveaux.",
  contact: "Nous Contacter",
  phone: "+49 151 525 54041",
  address: "Berlin, Allemagne",
  subscribe: "S'abonner",
  subscribeText: "Restez informé des derniers cours et offres de SkillSynergy.",
  send: "Envoyer",
};
