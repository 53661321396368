export default {
  title: "Server Error",
  description: `<p>
              Oops, something went wrong on our end. We're working hard to
              resolve the issue.
            </p>
            <p>
              Thank you for your patience. Please try again in a few moments.
            </p>`,
};
