import { isRouteErrorResponse, useRouteError } from "@remix-run/react";
import * as Sentry from "@sentry/remix";

import NotFoundError from "./components/httpErrors/notFoundError/NotFoundError";
import ServerError from "./components/httpErrors/serverError/ServerError";
import UnauthorizedError from "./components/httpErrors/unauthorizedError/UnauthorizedError";
import UnknownError from "./components/httpErrors/unknownError/UnknownError";

export default function ErrorBoundaryFunction() {
  const error = useRouteError();

  // Log the error to Sentry
  Sentry.captureException(error);

  if (isRouteErrorResponse(error)) {
    if (error.status === 404) {
      return <NotFoundError />;
    }

    // handle unauthorized errors
    if (error.status === 401 || error.status === 403) {
      return <UnauthorizedError errorStatus={error.status} />;
    }

    // handle 50x errors
    if (error.status >= 500) {
      return <ServerError errorStatus={error.status} />;
    }
  }

  return <UnknownError />;
}
