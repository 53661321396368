export default {
  welcome:
    "Welcome to SkillSynergy, where learning programming is made easy for all levels.",
  contact: "Contact us",
  phone: "+49 151 525 54041",
  address: "Berlin, Germany",
  subscribe: "Subscribe",
  subscribeText:
    "Stay updated with the latest courses and offers from SkillSynergy.",
  send: "Send",
};
