import { Link } from "@remix-run/react";
import { useState } from "react";

import { SUPPORTED_LANGUAGES } from "~/config/constants";
import { LanguageContextType } from "~/context/LanguageContext";
import { newLanguagePagePath } from "~/helpers/languageHelper";

const flags = {
  en: "🇬🇧",
  fr: "🇫🇷",
  de: "🇩🇪",
  ar: "🇸🇦",
};

const languages = {
  en: "English",
  fr: "Français",
  de: "Deutsch",
  ar: "عربي",
};

export default function LanguageDropdown({
  currentLanguage,
}: {
  currentLanguage: LanguageContextType["lang"];
}) {
  const [showDropdown, setShowDropdown] = useState(false);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  return (
    <>
      <li className="menu-item-has-children">
        <Link
          to={newLanguagePagePath(currentLanguage)}
          className="disabled-link"
        >
          {flags[currentLanguage]} {languages[currentLanguage]}
        </Link>

        <ul
          className="sub-menu"
          style={{ display: showDropdown ? "block" : "none" }}
        >
          {SUPPORTED_LANGUAGES.filter((lang) => lang !== currentLanguage).map(
            (lang) => {
              return (
                <li key={lang}>
                  <Link to={newLanguagePagePath(lang)}>
                    {flags[lang]} {languages[lang]}
                  </Link>
                </li>
              );
            },
          )}
        </ul>
        <span
          className={
            "cs-menu_dropdown_toggle " + (showDropdown ? "active" : "")
          }
          onClick={toggleDropdown}
          role="button"
          tabIndex={0}
          onKeyDown={() => {}}
        ></span>
      </li>
    </>
  );
}
