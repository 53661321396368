export default {
  welcome:
    "مرحبًا بكم في SkillSynergy، حيث يتم تبسيط تعلم البرمجة لجميع المستويات.",
  contact: "تواصل معنا",
  phone: "54041 525 151 49+",
  address: "برلين، ألمانيا",
  subscribe: "اشترك",
  subscribeText: "ابقَ على اطلاع بأحدث الدورات والعروض من SkillSynergy.",
  send: "إرسال",
};
