export default {
  welcome:
    "Willkommen bei SkillSynergy, wo das Programmieren für alle Niveaus leicht gemacht wird.",
  contact: "Kontaktieren Sie Uns",
  phone: "+49 151 525 54041",
  address: "Berlin, Deutschland",
  subscribe: "Abonnieren",
  subscribeText:
    "Bleiben Sie auf dem Laufenden über die neuesten Kurse und Angebote von SkillSynergy.",
  send: "Senden",
};
