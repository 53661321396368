export default {
  title: "Serverfehler",
  description: `<p>
              Ups, etwas ist schiefgelaufen. Wir arbeiten hart daran, das Problem zu
              beheben.
            </p>
            <p>
              Vielen Dank für Ihre Geduld. Bitte versuchen Sie es in wenigen Augenblicken
              erneut.
            </p>`,
};
