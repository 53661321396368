export default {
  title: "Erreur serveur",
  description: `<p>
              Oups, quelque chose a mal tourné de notre côté. Nous travaillons dur pour
              résoudre le problème.
            </p>
            <p>
              Merci de votre patience. Veuillez réessayer dans quelques instants.
            </p>`,
};
