export default {
  welcome:
    "مرحبًا بكم في SkillSynergy، حيث يتم تبسيط تعلم البرمجة لجميع المستويات.",
  courses: "الدورات",
  algorithms: "الخوارزميات",
  programming: "البرمجة الأساسية",
  architecture: "هندسة البرمجيات",
  web: "تطوير الويب",
  mobile: "تطوير التطبيقات المحمولة",
  server: "تطوير قواعد البيانات والخوادم",
  contact: "تواصل معنا",
  phone: "54041 525 151 49+",
  address: "برلين، ألمانيا",
  subscribe: "اشترك",
  subscribeText: "ابقَ على اطلاع بأحدث الدورات والعروض من SkillSynergy.",
  send: "إرسال",
  copyRight: `حقوق النشر © ${new Date().getFullYear()} SkillSynergy. جميع الحقوق محفوظة.`,
  terms: "شروط الخدمة",
  privacy: "سياسة الخصوصية",
};
