/* eslint-disable prettier/prettier */
export default function SiteNavigation() {
  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: JSON.stringify({
          "@context": "https://schema.org",
          "@graph": [
            {
              "@type": "WebSite",
              "@id": "https://skillsynergy.net/#website",
              "url": "https://skillsynergy.net/",
              "name": "SkillSynergy",
              "description": "SkillSynergy offers personalized and group-based programming courses for all skill levels.",
              "inLanguage": ["en", "fr", "de", "ar"]
            },
            {
              "@type": "Organization",
              "@id": "https://skillsynergy.net/#organization",
              "name": "SkillSynergy",
              "url": "https://skillsynergy.net",
              "logo": "https://skillsynergy.net/logo.svg",
              "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+4915152554041",
                "contactType": "Customer Support",
                "email": "contact@skillsynergy.net",
                "availableLanguage": ["English", "French", "German", "Arabic"]
              },
              "sameAs": [
                "https://www.linkedin.com/company/skillsynergy",
                "https://x.com/SkillSynergyNet",
                "https://www.youtube.com/@SkillSynergyDotNet",
                "https://www.instagram.com/skillsynergydotnet/",
                "https://www.facebook.com/profile.php?id=61559837181591",
                "https://www.tiktok.com/@skillsynergydotnet"
              ]
            },
            {
              "@type": "BreadcrumbList",
              "@id": "https://skillsynergy.net/#breadcrumb",
              "itemListElement": [
                {
                  "@type": "ListItem",
                  "position": 1,
                  "name": "Home",
                  "item": "https://skillsynergy.net/"
                },
                {
                  "@type": "ListItem",
                  "position": 2,
                  "name": "Courses",
                  "item": "https://skillsynergy.net/courses"
                },
                {
                  "@type": "ListItem",
                  "position": 3,
                  "name": "Pricing",
                  "item": "https://skillsynergy.net/pricing"
                },
                {
                  "@type": "ListItem",
                  "position": 4,
                  "name": "About",
                  "item": "https://skillsynergy.net/about"
                },
                {
                  "@type": "ListItem",
                  "position": 5,
                  "name": "Contact",
                  "item": "https://skillsynergy.net/contact"
                }
              ]
            },
            {
              "@type": "Course",
              "@id": "https://skillsynergy.net/course/algorithms#course",
              "name": "Algorithms",
              "description": "Comprehensive course on algorithms, from basics to advanced techniques.",
              "url": "https://skillsynergy.net/course/algorithms",
              "provider": {
                "@type": "Organization",
                "@id": "https://skillsynergy.net/#organization"
              },
              "hasCourseInstance": {
                "@type": "CourseInstance",
                "name": "Algorithms - Various Intensity",
                "courseMode": "Online",
                "courseWorkload": "PT8H",
                "inLanguage": "en"
              },
              "offers": {
                "@type": "Offer",
                "category": "Online Course",
                "description": "Available in various intensity levels",
                "availability": "https://schema.org/InStock"
              }
            },
            {
              "@type": "Course",
              "@id": "https://skillsynergy.net/course/programming#course",
              "name": "Programming Basics",
              "description": "Learn the foundations of programming to start building projects.",
              "url": "https://skillsynergy.net/course/programming",
              "provider": {
                "@type": "Organization",
                "@id": "https://skillsynergy.net/#organization"
              },
              "hasCourseInstance": {
                "@type": "CourseInstance",
                "name": "Programming Basics - Various Intensity",
                "courseMode": "Online",
                "courseWorkload": "PT8H",
                "inLanguage": "en",
              },
              "offers": {
                "@type": "Offer",
                "category": "Online Course",
                "description": "Available in various intensity levels",
                "availability": "https://schema.org/InStock"
              }
            },
            {
              "@type": "Course",
              "@id": "https://skillsynergy.net/course/architecture#course",
              "name": "Software Architecture",
              "description": "Develop a strong foundation in software architecture principles.",
              "url": "https://skillsynergy.net/course/architecture",
              "provider": {
                "@type": "Organization",
                "@id": "https://skillsynergy.net/#organization"
              },
              "hasCourseInstance": {
                "@type": "CourseInstance",
                "name": "Software Architecture - Various Intensity",
                "courseMode": "Online",
                "courseWorkload": "PT8H",
                "inLanguage": "en"
              },
              "offers": {
                "@type": "Offer",
                "category": "Online Course",
                "description": "Available in various intensity levels",
                "availability": "https://schema.org/InStock"
              }
            },
            {
              "@type": "Course",
              "@id": "https://skillsynergy.net/course/web#course",
              "name": "Web Development",
              "description": "Beginner to advanced web development techniques.",
              "url": "https://skillsynergy.net/course/web",
              "provider": {
                "@type": "Organization",
                "@id": "https://skillsynergy.net/#organization"
              },
              "hasCourseInstance": {
                "@type": "CourseInstance",
                "name": "Web Development - Various Intensity",
                "courseMode": "Online",
                "courseWorkload": "PT8H",
                "inLanguage": "en"
              },
              "offers": {
                "@type": "Offer",
                "category": "Online Course",
                "description": "Available in various intensity levels",
                "availability": "https://schema.org/InStock"
              }
            },
            {
              "@type": "Course",
              "@id": "https://skillsynergy.net/course/mobile#course",
              "name": "Mobile Development",
              "description": "Design and build mobile applications from scratch.",
              "url": "https://skillsynergy.net/course/mobile",
              "provider": {
                "@type": "Organization",
                "@id": "https://skillsynergy.net/#organization"
              },
              "hasCourseInstance": {
                "@type": "CourseInstance",
                "name": "Mobile Development - Various Intensity",
                "courseMode": "Online",
                "courseWorkload": "PT8H",
                "inLanguage": "en"
              },
              "offers": {
                "@type": "Offer",
                "category": "Online Course",
                "description": "Available in various intensity levels",
                "availability": "https://schema.org/InStock"
              }
            },
            {
              "@type": "Course",
              "@id": "https://skillsynergy.net/course/server#course",
              "name": "Server Management",
              "description": "Understand and manage server-side technologies.",
              "url": "https://skillsynergy.net/course/server",
              "provider": {
                "@type": "Organization",
                "@id": "https://skillsynergy.net/#organization"
              },
              "hasCourseInstance": {
                "@type": "CourseInstance",
                "name": "Server Management - Various Intensity",
                "courseMode": "Online",
                "courseWorkload": "PT8H",
                "inLanguage": "en"
              },
              "offers": {
                "@type": "Offer",
                "category": "Online Course",
                "description": "Available in various intensity levels",
                "availability": "https://schema.org/InStock"
              }
            }
          ]
        }),
      }}
    />
  );
}
