import { gsap } from "gsap";
import { useEffect } from "react";

import "./animatedCursor.scss";

export default function AnimatedCursor() {
  function cursorMovingAnimation(event: MouseEvent) {
    try {
      const timing = gsap.timeline({
        defaults: {
          x: event.clientX,
          y: event.clientY,
        },
      });

      timing
        .to(".cs-cursor_lg", {
          ease: "power2.out",
        })
        .to(
          ".cs-cursor_sm",
          {
            ease: "power2.out",
          },
          "-=0.4",
        );
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    // On hover, hide the cursors
    document
      .querySelectorAll(
        ".cs-text_btn, .cs-site_header a, .cs-down_btn, .cs-social_btns a, .cs-menu_widget",
      )
      .forEach((item) => {
        item.addEventListener("mouseenter", () => {
          gsap.to(".cs-cursor_lg, .cs-cursor_sm", {
            opacity: 0,
            duration: 0.3,
          });
        });

        item.addEventListener("mouseleave", () => {
          gsap.to(".cs-cursor_lg, .cs-cursor_sm", {
            opacity: 1,
            duration: 0.3,
          });
        });
      });

    document.addEventListener("mousemove", cursorMovingAnimation);
  }, []);

  return (
    <>
      <span className="cs-cursor_lg d"></span>
      <span className="cs-cursor_sm"></span>
    </>
  );
}
