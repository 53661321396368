import { NavLink } from "@remix-run/react";
import { useEffect, useState } from "react";

import { CURRENT_OFFER } from "~/config/constants";
import { useLanguage } from "~/context/LanguageContext";
import { getLocalizedPagePath } from "~/helpers/navigationHelper";

import "./Header.scss";
import i18n from "./i18n";
import LanguageDropdown from "./languageDropdown/languageDropdown";

// import ThemeSwitcher from "./themeSwitcher/ThemeSwitcher";

export default function Header() {
  const { lang } = useLanguage();
  const t = i18n[lang];

  // state for menu toggle
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  useEffect(() => {
    const header = document.querySelector(".cs-sticky_header") as HTMLElement;
    const headerHeight = header.offsetHeight + 30;
    let lastScrollTop = 0;

    window.addEventListener("scroll", function () {
      const headerMenuOpen = document.querySelector(
        ".cs-toggle_active",
      ) as HTMLElement;

      const windowTop = window.scrollY;
      if (headerMenuOpen) {
        header.classList.add("cs-gescout_show");
        header.classList.add("cs-gescout_sticky");
        return;
      }

      if (windowTop >= headerHeight) {
        header.classList.add("cs-gescout_sticky");
      } else {
        header.classList.remove("cs-gescout_sticky");
        header.classList.remove("cs-gescout_show");
      }

      if (header.classList.contains("cs-gescout_sticky")) {
        if (windowTop < lastScrollTop) {
          header.classList.add("cs-gescout_show");
        } else {
          header.classList.remove("cs-gescout_show");
        }
      }

      lastScrollTop = windowTop;
    });

    window.dispatchEvent(new Event("scroll"));
  }, []);

  return (
    <header className="cs-site_header cs-style1 text-uppercase cs-sticky_header">
      <div className="cs-main_header">
        <div className="container">
          <div className="cs-main_header_in">
            <div className="cs-main_header_left">
              <NavLink
                className="cs-site_branding"
                to={getLocalizedPagePath("")}
              >
                <img loading="lazy" src="/logo.svg" alt="Logo" />
              </NavLink>
            </div>
            <div className="cs-main_header_center">
              <div className="cs-nav cs-primary_font cs-medium">
                <ul
                  className="cs-nav_list"
                  style={{ display: showMenu ? "block" : "none" }}
                >
                  <li className="menu-item">
                    <NavLink to={getLocalizedPagePath("/courses")}>
                      {t.courses}
                    </NavLink>
                  </li>
                  <li className="menu-item">
                    <NavLink to={getLocalizedPagePath("/pricing")}>
                      {t.pricing}{" "}
                      <span className="pricing-offer"> -{CURRENT_OFFER}%</span>
                    </NavLink>
                  </li>
                  {/* TODO: create few blogs */}
                  {/* <li className="menu-item">
                    <NavLink to={getLocalizedPagePath("/blog")}>{t.blog}</NavLink>
                  </li> */}
                  <li className="menu-item">
                    <NavLink to={getLocalizedPagePath("/about")}>
                      {t.about}
                    </NavLink>
                  </li>
                  <li className="menu-item">
                    <NavLink to={getLocalizedPagePath("/contact")}>
                      {t.contact}
                    </NavLink>
                  </li>
                  <LanguageDropdown currentLanguage={lang} />
                  <li className="menu-item">
                    {/* dark or light theme switcher */}
                    {/* <ThemeSwitcher /> */}
                  </li>
                </ul>
                <span
                  className={
                    "cs-menu_toggle " + (showMenu ? "cs-toggle_active" : "")
                  }
                  onClick={toggleMenu}
                  role="button"
                  tabIndex={0}
                  onKeyDown={() => {}}
                >
                  <span></span>
                </span>
              </div>
            </div>
            <div className="cs-main_header_right">
              <div className="cs-toolbox">
                <span className="cs-icon_btn">
                  <span className="cs-icon_btn_in">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
