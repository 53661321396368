import parse from "html-react-parser";

import { useLanguage } from "~/context/LanguageContext";

import i18n from "./i18n";

export default function UnknownError() {
  const { lang } = useLanguage();
  const t = i18n[lang];

  return (
    <div
      className="cs-hero cs-style3 cs-bg cs-fixed_bg cs-shape_wrap_1"
      id="home"
    >
      <div className="cs-shape_1"></div>
      <div className="cs-shape_1"></div>
      <div className="cs-shape_1"></div>
      <div className="cs-circle_1"></div>
      <div className="cs-circle_2"></div>
      <div className="container">
        <div className="cs-hero_text">
          <h1
            className="cs-hero_title text-center wow fadeInRight"
            data-wow-duration="0.8s"
            data-wow-delay="0.2s"
          >
            {parse(t.title)}
          </h1>
          <div className="text-center">
            <p>{parse(t.description)}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
