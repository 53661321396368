export default {
  welcome:
    "Welcome to SkillSynergy, where learning programming is made easy for all levels.",
  courses: "Courses",
  algorithms: "Algorithms",
  programming: "Fundamental programming",
  architecture: "Software Design & Architecture",
  web: "Web development",
  mobile: "Mobile development",
  server: "Database and Server development",
  contact: "Contact us",
  phone: "+49 151 525 54041",
  address: "Berlin, Germany",
  subscribe: "Subscribe",
  subscribeText:
    "Stay updated with the latest courses and offers from SkillSynergy.",
  send: "Send",
  copyRight: `Copyright © ${new Date().getFullYear()} SkillSynergy. All Rights Reserved.`,
  terms: "Terms of Service",
  privacy: "Privacy Policy",
};
