import { FetcherWithComponents, useFetcher } from "@remix-run/react";
import { useEffect, useRef, useState } from "react";

import { useLanguage } from "~/context/LanguageContext";

import i18n from "./i18n";
import "./NewsletterSubscriptionForm.scss";

interface ContactFormResponse {
  success: boolean;
  errors: { [key: string]: string };
}

enum ButtonState {
  Submit = "submit",
  Sending = "sending",
  Success = "success",
  Failed = "failed",
}

export default function NewsletterSubscriptionForm() {
  const { lang } = useLanguage();
  const t = i18n[lang];

  const fetcher: FetcherWithComponents<ContactFormResponse> = useFetcher();
  const [buttonState, setButtonState] = useState(ButtonState.Submit);
  const formRef = useRef<HTMLFormElement>(null);

  // Handle state changes based on the fetcher's state
  useEffect(() => {
    if (fetcher.state === "submitting") {
      setButtonState(ButtonState.Sending); // "Sending Message...
    } else if (fetcher.state === "idle" && fetcher.data) {
      const success = fetcher.data?.success;
      // "Success Sending Message" : "Failed to Send Message"
      setButtonState(success ? ButtonState.Success : ButtonState.Failed);
      if (success) {
        formRef.current?.reset(); // reset form
      }

      setTimeout(() => {
        setButtonState(ButtonState.Submit); // reset button
      }, 3000);
    }
  }, [fetcher.state, fetcher.data]);

  return (
    <>
      <fetcher.Form
        id="cs-form"
        className="cs-newsletter_form"
        ref={formRef}
        method="post"
        action="/newsletter/subscribe"
      >
        <input
          type="email"
          name="email"
          required
          className="cs-newsletter_input"
          placeholder="example@gmail.com"
        />
        <input type="text" name="address" style={{ display: "none" }} />
        <button
          className={`cs-newsletter_btn ${buttonState.toLowerCase()}`}
          disabled={fetcher.state === "submitting"}
          type="submit"
        >
          <span>{t[buttonState]}</span>
        </button>
      </fetcher.Form>
    </>
  );
}
