import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useLocation,
  useRouteError,
} from "@remix-run/react";
import { withSentry } from "@sentry/remix";

import Footer from "~/components/footer/Footer";
import Header from "~/components/header/Header";
import ScrollUp from "~/components/scrollUp/ScrollUp";
import { SUPPORTED_LANGUAGES } from "~/config/constants";
import {
  LanguageContextType,
  LanguageProvider,
} from "~/context/LanguageContext";
import Sidebar from "~/routes/($locale)._index/components/sidebar/Sidebar";

import AnimatedCursor from "./components/animatedCursor/AnimatedCursor";
import CrispButton from "./components/crispButton/CrispButton";
import Termly from "./components/termly/Termly";
import { CountryProvider } from "./context/CountryContext";
import RootErrorBoundary from "./root.errorBoundary";
import RootLoader, { RootLoaderData } from "./root.loader";
import RootMeta from "./root.meta";
import SiteNavigation from "./seo/siteNavigation";

// main.js or main.ts
import "@fontsource/open-sans/400.css";
import "@fontsource/open-sans/600.css";
import "@fontsource/open-sans/700.css";

import "@fontsource/poppins/400.css";
import "@fontsource/poppins/600.css";
import "@fontsource/poppins/700.css";
import "~/index.scss";

export const ErrorBoundary = RootErrorBoundary;
export const meta = RootMeta;
export const loader = RootLoader;

export function Layout({ children }: { children: React.ReactNode }) {
  const { pathname } = useLocation();
  let lang = pathname.split("/")[1] as LanguageContextType["lang"];
  if (!lang || !SUPPORTED_LANGUAGES.includes(lang)) {
    lang = "en";
  }

  const error = useRouteError();
  let countryCode = "us";
  if (!error) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const data = useLoaderData<RootLoaderData | undefined>();
    if (data) {
      countryCode = data.countryCode;
    }
  }

  return (
    <html lang={lang}>
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <SiteNavigation />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <Meta />
        <Termly />

        {/* <!-- vendor css --> */}
        <link rel="stylesheet" href="/css/plugins/bootstrap.min.css" />
        <link rel="stylesheet" href="/css/plugins/animate.css" />

        <Links />
      </head>
      <body className={lang === "ar" ? "rtl" : ""}>
        <AnimatedCursor />
        <CountryProvider country={countryCode}>
          <LanguageProvider lang={lang}>
            <Header />
            <Sidebar />
            {children}
            <Footer />
            <ScrollUp />
            <CrispButton />
          </LanguageProvider>
        </CountryProvider>
        <ScrollRestoration />
        <Scripts />

        {/* <!-- vendor js --> */}
        <script src="/js/plugins/jquery-3.6.0.min.js"></script>
        <script src="/js/plugins/wow.min.js"></script>
        <script src="/js/main.js"></script>
      </body>
    </html>
  );
}

function App() {
  return <Outlet />;
}

export default withSentry(App);
