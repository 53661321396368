import { Link } from "@remix-run/react";

import {
  CONTACT_EMAIL,
  FACEBOOK_URL,
  LINKED_IN_URL,
  X_URL,
  YOUTUBE_URL,
} from "~/config/constants";
import { useLanguage } from "~/context/LanguageContext";
import { getLocalizedPagePath } from "~/helpers/navigationHelper";

import i18n from "./i18n";
import "./Footer.scss";
import NewsletterSubscriptionForm from "../newsletterSubscription/NewsletterSubscriptionForm";

export default function Header() {
  const { lang } = useLanguage();
  const t = i18n[lang];

  return (
    <footer className="cs-footer">
      <div className="cs-footer_main">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-sm-6">
              <div className="cs-footer_item">
                <div className="cs-text_widget">
                  <img loading="lazy" src="/logo.svg" alt="Thumb" />
                  <p>{t.welcome}</p>
                </div>
                <div className="cs-social_btns cs-style1">
                  <a
                    href={LINKED_IN_URL}
                    target="_blank"
                    rel="noreferrer"
                    className="cs-center"
                  >
                    <svg
                      width="15"
                      height="15"
                      viewBox="0 0 15 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.04799 13.7497H1.45647V5.4043H4.04799V13.7497ZM2.75084 4.2659C1.92215 4.2659 1.25 3.57952 1.25 2.75084C1.25 2.35279 1.40812 1.97105 1.68958 1.68958C1.97105 1.40812 2.35279 1.25 2.75084 1.25C3.14888 1.25 3.53063 1.40812 3.81209 1.68958C4.09355 1.97105 4.25167 2.35279 4.25167 2.75084C4.25167 3.57952 3.57924 4.2659 2.75084 4.2659ZM13.7472 13.7497H11.1613V9.68722C11.1613 8.71903 11.1417 7.4774 9.81389 7.4774C8.46652 7.4774 8.26004 8.5293 8.26004 9.61747V13.7497H5.67132V5.4043H8.15681V6.54269H8.19308C8.53906 5.887 9.38421 5.19503 10.6451 5.19503C13.2679 5.19503 13.75 6.92215 13.75 9.16546V13.7497H13.7472Z"
                        fill="white"
                      />
                    </svg>
                  </a>
                  <a
                    href={X_URL}
                    target="_blank"
                    rel="noreferrer"
                    className="cs-center"
                  >
                    <svg
                      width="13"
                      height="11"
                      viewBox="0 0 13 11"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.4651 2.95396C11.4731 3.065 11.4731 3.17606 11.4731 3.2871C11.4731 6.67383 8.89535 10.5761 4.18402 10.5761C2.73255 10.5761 1.38421 10.1557 0.25 9.42608C0.456226 9.44986 0.654494 9.4578 0.868655 9.4578C2.06629 9.4578 3.16879 9.0533 4.04918 8.36326C2.92291 8.33946 1.97906 7.60183 1.65386 6.5866C1.81251 6.61038 1.97112 6.62624 2.1377 6.62624C2.36771 6.62624 2.59774 6.59451 2.81188 6.53901C1.63802 6.30105 0.757595 5.26996 0.757595 4.02472V3.99301C1.09864 4.18336 1.49524 4.30233 1.91558 4.31818C1.22554 3.85814 0.773464 3.07294 0.773464 2.1846C0.773464 1.70872 0.900344 1.27249 1.12244 0.891774C2.38355 2.44635 4.27919 3.46156 6.40481 3.57262C6.36516 3.38226 6.34136 3.184 6.34136 2.9857C6.34136 1.57388 7.4835 0.423828 8.90323 0.423828C9.64086 0.423828 10.3071 0.733156 10.7751 1.23284C11.354 1.1218 11.9093 0.907643 12.401 0.614185C12.2106 1.20906 11.8061 1.70875 11.2748 2.02598C11.7903 1.97049 12.29 1.82769 12.75 1.62942C12.4011 2.13702 11.9648 2.5891 11.4651 2.95396V2.95396Z"
                        fill="white"
                      />
                    </svg>
                  </a>
                  <a
                    href={YOUTUBE_URL}
                    target="_blank"
                    rel="noreferrer"
                    className="cs-center"
                  >
                    <svg
                      width="13"
                      height="9"
                      viewBox="0 0 13 9"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12.4888 1.48066C12.345 0.939353 11.9215 0.513038 11.3837 0.368362C10.4089 0.105469 6.5 0.105469 6.5 0.105469C6.5 0.105469 2.59116 0.105469 1.61633 0.368362C1.07853 0.513061 0.65496 0.939353 0.5112 1.48066C0.25 2.4618 0.25 4.50887 0.25 4.50887C0.25 4.50887 0.25 6.55595 0.5112 7.53709C0.65496 8.0784 1.07853 8.48695 1.61633 8.63163C2.59116 8.89452 6.5 8.89452 6.5 8.89452C6.5 8.89452 10.4088 8.89452 11.3837 8.63163C11.9215 8.48695 12.345 8.0784 12.4888 7.53709C12.75 6.55595 12.75 4.50887 12.75 4.50887C12.75 4.50887 12.75 2.4618 12.4888 1.48066V1.48066ZM5.22158 6.36746V2.65029L8.48861 4.50892L5.22158 6.36746V6.36746Z"
                        fill="white"
                      />
                    </svg>
                  </a>
                  <a
                    href={FACEBOOK_URL}
                    target="_blank"
                    rel="noreferrer"
                    className="cs-center"
                  >
                    <svg
                      width="13"
                      height="13"
                      viewBox="0 0 13 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2.87612 8.149C2.87612 8.87165 2.28571 9.46205 1.56306 9.46205C0.840402 9.46205 0.25 8.87165 0.25 8.149C0.25 7.42634 0.840402 6.83594 1.56306 6.83594H2.87612V8.149ZM3.53795 8.149C3.53795 7.42634 4.12835 6.83594 4.851 6.83594C5.57366 6.83594 6.16406 7.42634 6.16406 8.149V11.4369C6.16406 12.1596 5.57366 12.75 4.851 12.75C4.12835 12.75 3.53795 12.1596 3.53795 11.4369V8.149ZM4.851 2.87612C4.12835 2.87612 3.53795 2.28571 3.53795 1.56306C3.53795 0.840402 4.12835 0.25 4.851 0.25C5.57366 0.25 6.16406 0.840402 6.16406 1.56306V2.87612H4.851V2.87612ZM4.851 3.53795C5.57366 3.53795 6.16406 4.12835 6.16406 4.851C6.16406 5.57366 5.57366 6.16406 4.851 6.16406H1.56306C0.840402 6.16406 0.25 5.57366 0.25 4.851C0.25 4.12835 0.840402 3.53795 1.56306 3.53795H4.851V3.53795ZM10.1239 4.851C10.1239 4.12835 10.7143 3.53795 11.4369 3.53795C12.1596 3.53795 12.75 4.12835 12.75 4.851C12.75 5.57366 12.1596 6.16406 11.4369 6.16406H10.1239V4.851V4.851ZM9.46205 4.851C9.46205 5.57366 8.87165 6.16406 8.149 6.16406C7.42634 6.16406 6.83594 5.57366 6.83594 4.851V1.56306C6.83594 0.840402 7.42634 0.25 8.149 0.25C8.87165 0.25 9.46205 0.840402 9.46205 1.56306V4.851V4.851ZM8.149 10.1239C8.87165 10.1239 9.46205 10.7143 9.46205 11.4369C9.46205 12.1596 8.87165 12.75 8.149 12.75C7.42634 12.75 6.83594 12.1596 6.83594 11.4369V10.1239H8.149ZM8.149 9.46205C7.42634 9.46205 6.83594 8.87165 6.83594 8.149C6.83594 7.42634 7.42634 6.83594 8.149 6.83594H11.4369C12.1596 6.83594 12.75 7.42634 12.75 8.149C12.75 8.87165 12.1596 9.46205 11.4369 9.46205H8.149Z"
                        fill="white"
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="cs-footer_item">
                <h2 className="cs-widget_title">{t.courses}</h2>
                <ul className="cs-menu_widget cs-mp0">
                  <li>
                    <Link to={getLocalizedPagePath("/course/algorithms")}>
                      {t.algorithms}
                    </Link>
                  </li>
                  <li>
                    <Link to={getLocalizedPagePath("/course/programming")}>
                      {t.programming}
                    </Link>
                  </li>
                  <li>
                    <Link to={getLocalizedPagePath("/course/architecture")}>
                      {t.architecture}
                    </Link>
                  </li>
                  <li>
                    <Link to={getLocalizedPagePath("/course/mobile")}>
                      {t.mobile}
                    </Link>
                  </li>
                  <li>
                    <Link to={getLocalizedPagePath("/course/web")}>
                      {t.web}
                    </Link>
                  </li>
                  <li>
                    <Link to={getLocalizedPagePath("/course/server")}>
                      {t.server}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="cs-footer_item">
                <h2 className="cs-widget_title">{t.contact}</h2>
                <ul className="cs-menu_widget cs-mp0">
                  <li>{t.address}</li>
                  <li>{t.phone}</li>
                  <li>{CONTACT_EMAIL}</li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="cs-footer_item">
                <h2 className="cs-widget_title">{t.subscribe}</h2>
                <div className="cs-newsletter cs-style1">
                  <NewsletterSubscriptionForm />
                  <div className="cs-newsletter_text">{t.subscribeText}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="cs-bottom_footer">
          <div className="cs-bottom_footer_left">
            <div className="cs-copyright">{t.copyRight}</div>
          </div>
          <div className="cs-bottom_footer_right">
            <ul className="cs-footer_links cs-mp0">
              <li>
                <Link to={getLocalizedPagePath("/terms")}>{t.terms}</Link>
              </li>
              <li>
                <Link to={getLocalizedPagePath("/privacy")}>{t.privacy}</Link>
              </li>
              <li>
                <Link to="" className="termly-display-preferences">
                  Consent Preferences
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}
