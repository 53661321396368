/* eslint-disable react-hooks/rules-of-hooks */
import { useLocation } from "@remix-run/react";

import { LanguageContextType, useLanguage } from "~/context/LanguageContext";

export const newLanguagePagePath = (nextLng: LanguageContextType["lang"]) => {
  const { lang } = useLanguage();
  let { pathname } = useLocation();

  // If the language is already the same, return the current path
  if (nextLng === lang) {
    return pathname;
  }

  // If switching to English, remove the lang part from the path
  if (nextLng === "en") {
    return pathname.replace(`/${lang}`, "/").replace("//", "/"); // Ensure no double slashes
  }

  // If there's no lang in the current path, prepend the next lang
  if (lang === "en") {
    pathname = `/${nextLng}${pathname}`;
  } else {
    // Replace the current lang with the new one
    pathname = pathname.replace(`/${lang}`, `/${nextLng}`);
  }

  // Remove trailing slash if present, except for root path "/"
  if (pathname.endsWith("/")) {
    pathname = pathname.slice(0, -1); // Remove the trailing slash
  }

  return pathname;
};
